import {
  Favorite,
  FavoriteBorder,
  FavoriteOutlined,
} from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Pagination,
  Select,
  Skeleton,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useErrorBoundary } from "react-error-boundary";
import { useHistory } from "react-router";
import { postAsync } from "../../../helpers/asyncFetch";
import isAdminUser from "../../../helpers/isAdminUser";
import useGetData from "../../../hooks/dataFetchers/useGetData";
import useQuery from "../../../hooks/useQuery";
import Img from "../../../shared/BasicHTML/Img";
import Design from "../../Designs/models/Design";
import V3ApiClient from "../helpers/V3ApiClient";
import { getCookie } from "../helpers/cookies";
import { IntegratedContext } from "../layout/IntegratedLayout";
import DesignTag from "../models/DesignTag";
import FavoriteDesign from "../models/FavoriteDesign";
import GalleryDesign from "../models/GalleryDesign";
import PaginatedDataResponse, {
  ApiPagination,
} from "../models/PaginatedDataResponse";
import { PricingTable } from "./components/PricingTable";
import { PricingWithDescription } from "./models/pricing";

interface DesignGalleryState {
  selectedOccasions: string[];
  selectedIndustries: string[];
  selectedSizes: string[];
  isOnlyFavorites: boolean;
  mode: "all" | "template" | "owned" | "custom";
}

interface TagState {
  loading: boolean;
  occasions: DesignTag[];
  industries: DesignTag[];
}

interface DesignState {
  initialized: boolean;
  loading: boolean;
  designs: GalleryDesign[];
  pagination: ApiPagination;
}

interface FavoriteState {
  loading: boolean;
  favorites: FavoriteDesign[];
}

interface DesignGalleryProps {
  isPortalGallery?: boolean;
}

const DesignGallery = ({ isPortalGallery = false }: DesignGalleryProps) => {
  const integratedContext = useContext(IntegratedContext);
  const { showBoundary } = useErrorBoundary();
  const clientRef = useRef(new V3ApiClient({ onError: showBoundary }));
  const client = clientRef.current;
  const history = useHistory();
  const query = useQuery();
  const searchDebounceRef = useRef<NodeJS.Timeout>();
  const searchTermRef = useRef("");

  const [selectedDesign, setSelectedDesign] = useState<number>();
  const [designSide, setDesignSide] = useState("front");
  const [selectedDesignTags, setSelectedDesignTags] = useState<TagState>({
    loading: true,
    occasions: [],
    industries: [],
  });
  const {
    data: designPricing,
    isLoading: pricingLoading,
    error: pricingError,
    updateData: setPricing,
  } = useGetData<PricingWithDescription[]>("/api/pricing");
  const [scratchOpen, setScratchOpen] = useState(false);
  const [newDesign, setNewDesign] = useState({
    size: "46",
    name: "",
    isTemplate: false,
  });
  const [searchTerm, setSearchTerm] = useState("");
  searchTermRef.current = searchTerm;
  const [tags, setTags] = useState<TagState>({
    loading: true,
    occasions: [],
    industries: [],
  });
  const [designs, setDesigns] = useState<DesignState>({
    initialized: false,
    loading: true,
    designs: [],
    pagination: {
      page: 1,
      perPage: 10,
      totalResults: 1,
      totalPages: 1,
      nextPage: "",
      prevPage: "",
    },
  });
  const [favorites, setFavorites] = useState<FavoriteState>({
    loading: true,
    favorites: [],
  });
  const [state, setState] = useState<DesignGalleryState>({
    selectedOccasions: [],
    selectedIndustries: [],
    selectedSizes: [],
    isOnlyFavorites: false,
    mode: "all",
  });

  async function getTags() {
    const tags: DesignTag[] = await client.get("/gallery/tags");
    setTags({
      loading: false,
      occasions: tags.filter((x) => x.tagGroup.toLowerCase() === "occasion"),
      industries: tags.filter((x) => x.tagGroup.toLowerCase() === "industry"),
    });
  }

  async function handleCreateClick() {
    const token = getCookie("token") ?? undefined;
    const design = await postAsync<{ design: Design }>(
      `/designer`,
      newDesign,
      false,
      token
    );

    if (design && design.design) {
      if (isPortalGallery) {
        history.push(`/designs/designer/${design.design.designID}/edit`);
      } else {
        const origin = query.get("origin");
        history.push(
          `/integrated/editor/${design.design.designID}?token=${token}&origin=${origin}&source=${integratedContext.source}`
        );
      }
    }
  }

  async function getDesignTags(designID: number) {
    setSelectedDesignTags({ loading: true, occasions: [], industries: [] });
    const tags: DesignTag[] = await client.get(`/gallery/tags/${designID}`);
    setSelectedDesignTags({
      loading: false,
      occasions: tags.filter((x) => x.tagGroup.toLowerCase() === "occasion"),
      industries: tags.filter((x) => x.tagGroup.toLowerCase() === "industry"),
    });
  }

  async function getFavorites() {
    const favorites: FavoriteDesign[] = await client.get("/gallery/favorites");
    setFavorites({
      loading: false,
      favorites: favorites,
    });
  }

  const defaultSizes =
    integratedContext.source && integratedContext.source != "sf"
      ? ["46", "68", "611", "811"]
      : ["46", "68", "611"];

  async function getDesigns(page?: number) {
    const queries: { key: string; value: string }[] = [
      { key: "perPage", value: "8" },
    ];
    if (searchTermRef.current && searchTermRef.current.trim()) {
      queries.push({ key: "search", value: searchTermRef.current.trim() });
    }
    if (designs.initialized) {
      setDesigns({ ...designs, loading: true });
    }

    queries.push({
      key: "size",
      value:
        state.selectedSizes.length > 0
          ? state.selectedSizes.join(",")
          : defaultSizes.join(","),
    });

    if (state.selectedIndustries.length) {
      queries.push({
        key: "industry",
        value: state.selectedIndustries.join(","),
      });
    }
    if (state.selectedOccasions.length) {
      queries.push({
        key: "occasion",
        value: state.selectedOccasions.join(","),
      });
    }
    if (state.isOnlyFavorites) {
      queries.push({ key: "favorites", value: "true" });
    }
    if (page) {
      queries.push({ key: "page", value: page.toString() });
    }
    if (state.mode) {
      queries.push({ key: "mode", value: state.mode });
    }
    const paginatedDesigns: PaginatedDataResponse<GalleryDesign> =
      await client.get("/gallery/designs", queries);
    setDesigns({
      loading: false,
      initialized: true,
      designs: paginatedDesigns.results,
      pagination: paginatedDesigns.pagination,
    });
  }

  function handleSizeCheck(e: React.ChangeEvent<HTMLInputElement>) {
    if (e.target.checked) {
      setState({
        ...state,
        selectedSizes: [...state.selectedSizes, e.target.value],
      });
    } else {
      setState({
        ...state,
        selectedSizes: state.selectedSizes.filter((x) => x !== e.target.value),
      });
    }
  }

  function handleSearch(e: React.ChangeEvent<HTMLInputElement>) {
    setSearchTerm(e.target.value);
    if (searchDebounceRef.current) {
      clearTimeout(searchDebounceRef.current);
    }
    searchDebounceRef.current = setTimeout(() => {
      getDesigns();
    }, 500);
  }

  function clearSearch() {
    setSearchTerm("");
    searchTermRef.current = "";
    getDesigns();
  }

  function handleIndustryCheck(e: React.ChangeEvent<HTMLInputElement>) {
    if (e.target.checked) {
      setState({
        ...state,
        selectedIndustries: [...state.selectedIndustries, e.target.value],
      });
    } else {
      setState({
        ...state,
        selectedIndustries: state.selectedIndustries.filter(
          (x) => x !== e.target.value
        ),
      });
    }
  }

  function selectDesign(designID: number) {
    getDesignTags(designID);
    setSelectedDesign(designID);
  }

  function handleOccasionCheck(e: React.ChangeEvent<HTMLInputElement>) {
    if (e.target.checked) {
      setState({
        ...state,
        selectedOccasions: [...state.selectedOccasions, e.target.value],
      });
    } else {
      setState({
        ...state,
        selectedOccasions: state.selectedOccasions.filter(
          (x) => x !== e.target.value
        ),
      });
    }
  }

  function initialize() {
    getTags();
    getFavorites();
    getDesigns();
  }

  async function addToFavorites(designID: number) {
    await client.post("/gallery/favorites", {
      designID,
    });
    getFavorites();
  }

  async function removeFromFavorites(designID: number) {
    await client.delete("/gallery/favorites", {
      designID,
    });
    getFavorites();
  }

  function handleChangePage(e: React.ChangeEvent<unknown>, page: number) {
    getDesigns(page);
  }

  async function handleGetEditor() {
    const res = await client.get<{ url: string }>(
      `/design/${selectedDesign}/edit`
    );
    const token = getCookie("token");
    if (isPortalGallery) {
      const url = res.url.split("?")[0];
      const path = url.split("/");
      const id = path[path.length - 1];
      history.push(`/designs/designer/${id}/edit`);
    } else {
      const path = res.url
        .replace(window.location.host, "")
        .replace("https://", "");

      const origin = query.get("origin");

      history.push(`${path}&origin=${origin}`);
    }
    //console.log(res.url);
  }

  function handleIndustryTagClick(e: React.MouseEvent, tagID: number) {
    e.preventDefault();
    setSelectedDesign(undefined);
    setState({
      ...state,
      selectedIndustries: [tagID.toString()],
    });
  }

  function handleOccasionTagClick(e: React.MouseEvent, tagID: number) {
    e.preventDefault();
    setSelectedDesign(undefined);
    setState({
      ...state,
      selectedOccasions: [tagID.toString()],
    });
  }

  function getDesignLoadingBoxes() {
    const designLoadingBoxes = [];
    for (let i = 0; i < 12; i++) {
      designLoadingBoxes.push(
        <Grid item xs={6} md={6} lg={4} xl={3} key={i}>
          <Skeleton
            variant="rectangular"
            width="100%"
            sx={{ paddingTop: "calc(4.25 / 6 * 100%)", height: "100%" }}
          />
        </Grid>
      );
    }
    return designLoadingBoxes;
  }

  useEffect(initialize, []);

  useEffect(() => {
    if (designs.initialized) {
      getDesigns();
    }
  }, [
    state.selectedIndustries,
    state.selectedOccasions,
    state.selectedSizes,
    state.isOnlyFavorites,
    state.mode,
  ]);
  function handleScratchClose() {
    setScratchOpen(false);
    setNewDesign({ size: "46", name: "", isTemplate: false });
  }

  function handleViewModeChange(v: "all" | "template" | "owned") {
    setState({ ...state, mode: v });
  }

  function getLabel(size: string) {
    switch (size) {
      case "46":
        return <span>4.25&quot; x 6&quot; Postcard</span>;
      case "68":
        return <span>6&quot; x 8.5&quot; Postcard</span>;
      case "611":
        return <span>6&quot; x 11&quot; Postcard</span>;
      case "811":
        return <span>8.5&quot; x 11&quot; Letter</span>;
      case "BRO":
        return <span>Brochure</span>;
      default:
        return <span></span>;
    }
  }

  function getScratchModal() {
    if (scratchOpen && designPricing) {
      return (
        <Box
          sx={{
            position: "absolute",
            right: 0,
            top: isPortalGallery
              ? isAdminUser()
                ? `${79 + 42}px`
                : `${79}px`
              : 0,
            bottom: 0,
            height: isPortalGallery
              ? isAdminUser()
                ? `calc(100vh - 79px - 42px)`
                : `calc(100vh - 79px)`
              : "100vh",

            maxHeight: isPortalGallery
              ? isAdminUser()
                ? "calc(100vh - 79px - 42px)"
                : "calc(100vh - 79px)"
              : undefined,
            overflow: isPortalGallery ? "hidden" : undefined,
            width: "100%",
            background: "rgba(0,0,0,.5)",
            display: "flex",
            justifyContent: "flex-end",
          }}
          onClick={() => setSelectedDesign(undefined)}
        >
          <Box
            sx={{
              width: "auto",
              background: "white",
              height: "100%",
            }}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <Grid container spacing={3}>
              <Grid
                item
                sx={{
                  width: "375px",
                }}
              >
                <Box
                  sx={{
                    height: "100vh",
                    overflow: "auto",
                    padding: 3,
                    position: "relative",
                    borderLeft: "solid 1px rgba(0,0,0,.125)",
                  }}
                >
                  <IconButton
                    onClick={() => handleScratchClose()}
                    sx={{
                      position: "absolute",
                      top: 0,
                      right: 0,
                      mr: 1,
                      mt: 1,
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                  <Box
                    sx={{
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                      paddingRight: 0.5,
                      // overflowY: "auto",
                    }}
                  >
                    <Box sx={{ mb: 2 }}>
                      <Typography variant="h4" gutterBottom>
                        Start From Scratch
                      </Typography>
                    </Box>
                    <Box sx={{ mb: 2 }}>
                      <FormGroup sx={{ mb: 2 }}>
                        <InputLabel>
                          Enter a name for your design (Required)
                        </InputLabel>
                        <TextField
                          size="small"
                          value={newDesign.name}
                          onChange={(e) => {
                            setNewDesign({
                              ...newDesign,
                              name: e.target.value,
                            });
                          }}
                          hiddenLabel
                          fullWidth
                        />
                      </FormGroup>
                      <FormGroup>
                        <InputLabel>Select a size</InputLabel>
                        <Select
                          size="small"
                          value={newDesign.size}
                          onChange={(e) => {
                            setNewDesign({
                              ...newDesign,
                              size: e.target.value,
                            });
                          }}
                        >
                          <MenuItem value="46">
                            4.25&quot; x 6&quot; Postcard
                          </MenuItem>
                          weq
                          <MenuItem value="68">
                            6&quot; x 8.5&quot; Postcard
                          </MenuItem>
                          <MenuItem value="611">
                            6&quot; x 11&quot; Postcard
                          </MenuItem>
                          {integratedContext.source &&
                            integratedContext.source != "sf" && (
                              <MenuItem value="811">
                                8.5&quot; x 11&quot; Letter
                              </MenuItem>
                            )}
                        </Select>
                      </FormGroup>
                    </Box>
                    <PricingTable
                      size={newDesign.size}
                      pricing={designPricing}
                    />
                    <Box
                      sx={{
                        paddingBottom: 2,
                      }}
                    >
                      <Button
                        onClick={handleCreateClick}
                        variant="contained"
                        fullWidth
                        size="large"
                        disabled={!newDesign.name || !newDesign.name.trim()}
                      >
                        Create Design
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      );
    }
    return null;
  }

  function getSelectedDesignModal() {
    if (selectedDesign) {
      const design = designs.designs.find((x) => x.designID === selectedDesign);
      const isFavorited = Boolean(
        favorites.favorites.find((x) => x.designID === selectedDesign)
      );
      if (design) {
        return (
          <Box
            sx={{
              position: "absolute",
              right: 0,
              top: isPortalGallery
                ? isAdminUser()
                  ? `${79 + 42}px`
                  : `${79}px`
                : 0,
              bottom: 0,
              height: isPortalGallery
                ? isAdminUser()
                  ? `calc(100vh - 79px - 42px)`
                  : `calc(100vh - 79px)`
                : "100vh",
              maxHeight: isPortalGallery
                ? isAdminUser()
                  ? "calc(100vh - 79px - 42px)"
                  : "calc(100vh - 79px)"
                : undefined,
              overflow: isPortalGallery ? "hidden" : undefined,
              width: "100%",
              background: "rgba(0,0,0,.5)",
              display: "flex",
              justifyContent: "flex-end",
            }}
            onClick={() => setSelectedDesign(undefined)}
          >
            <Box
              sx={{
                width: isPortalGallery
                  ? "calc(100% - 600px)"
                  : "calc(100% - 261px)",
                background: "white",
                height: "100%",
              }}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <Grid container spacing={3}>
                <Grid item xs={12} md={8} lg xl sx={{ height: "100%" }}>
                  <Box
                    sx={{
                      pr: 3,
                      background: "#f5f5f5",
                      height: "100vh",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                      position: "relative",
                    }}
                  >
                    <Box sx={{ width: "100%", mb: 4 }}>
                      {designSide === "front" && (
                        <Img
                          src={design.proofFront}
                          style={{
                            width: "50%",
                            margin: "auto",
                          }}
                        />
                      )}
                      {designSide === "back" && (
                        <Img
                          src={design.proofBack}
                          style={{ width: "50%", margin: "auto" }}
                        />
                      )}
                    </Box>
                    {design.proofBack && (
                      <ToggleButtonGroup
                        value={designSide}
                        exclusive
                        onChange={(e, v) => {
                          if (v) {
                            setDesignSide(v);
                          }
                        }}
                      >
                        <ToggleButton value="front">Front</ToggleButton>
                        <ToggleButton value="back">Back</ToggleButton>
                      </ToggleButtonGroup>
                    )}

                    <Box sx={{ position: "absolute", top: 0, right: 0, p: 3 }}>
                      {!isFavorited && (
                        <IconButton
                          onClick={() => addToFavorites(selectedDesign)}
                          sx={{ background: "white" }}
                        >
                          <FavoriteBorder />
                        </IconButton>
                      )}
                      {isFavorited && (
                        <IconButton
                          onClick={() => removeFromFavorites(selectedDesign)}
                          sx={{ background: "white" }}
                        >
                          <FavoriteOutlined />
                        </IconButton>
                      )}
                    </Box>
                  </Box>
                </Grid>
                <Grid
                  item
                  sx={{
                    borderLeft: "solid 1px rgba(0,0,0,.125)",
                    width: "375px",
                  }}
                >
                  <Box
                    sx={{
                      height: "100vh",
                      overflow: "auto",
                      padding: 3,
                      position: "relative",
                    }}
                  >
                    <IconButton
                      onClick={() => setSelectedDesign(undefined)}
                      sx={{
                        position: "absolute",
                        top: 0,
                        right: 0,
                        mr: 1,
                        mt: 1,
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                    <Box
                      sx={{
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <Box>
                        <Box>
                          <Box>
                            <Typography variant="h5" gutterBottom>
                              Size
                            </Typography>
                          </Box>
                          <Typography>{design.size.label}</Typography>
                          <Divider sx={{ my: 3 }} />
                        </Box>

                        {selectedDesignTags.industries.length > 0 && (
                          <Box>
                            <Box>
                              <Typography variant="h5" gutterBottom>
                                Industry
                              </Typography>
                            </Box>
                            {selectedDesignTags.industries.map((x, index) => (
                              <span key={x.tagID}>
                                <a
                                  href="#"
                                  onClick={(e) =>
                                    handleIndustryTagClick(e, x.tagID)
                                  }
                                >
                                  {x.tagName}
                                </a>
                                {selectedDesignTags.industries.length > 1 &&
                                  index !==
                                    selectedDesignTags.industries.length - 1 &&
                                  ","}
                              </span>
                            ))}
                            <Divider sx={{ my: 3 }} />
                          </Box>
                        )}
                        {selectedDesignTags.occasions.length > 0 && (
                          <Box>
                            <Box>
                              <Typography variant="h5" gutterBottom>
                                Occasion
                              </Typography>
                            </Box>
                            {selectedDesignTags.occasions.map((x, index) => (
                              <span key={x.tagID}>
                                <a
                                  href="#"
                                  onClick={(e) =>
                                    handleOccasionTagClick(e, x.tagID)
                                  }
                                >
                                  {x.tagName}
                                </a>
                                {selectedDesignTags.occasions.length > 1 &&
                                  index !==
                                    selectedDesignTags.occasions.length - 1 &&
                                  ","}
                              </span>
                            ))}
                            <Divider sx={{ my: 3 }} />
                          </Box>
                        )}
                      </Box>
                      <Box>
                        <PricingTable
                          size={design.size.key}
                          pricing={designPricing || []}
                        />
                      </Box>

                      <Box sx={{ mt: 2, paddingBottom: 2 }}>
                        <Button
                          onClick={handleGetEditor}
                          variant="contained"
                          fullWidth
                          size="large"
                        >
                          Customize Design
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        );
      }
    }
    return null;
  }

  const getMinimumPrice = (design: GalleryDesign) => {
    if (!designPricing) {
      return 0;
    }

    const pricing = designPricing.filter(
      (x) =>
        x.size === design.size.key &&
        ["FirstClass", "Standard"].includes(x.mailClass)
    );
    const minimum = pricing.sort((a, b) =>
      a.price && b.price ? a.price - b.price : 0
    )[0]?.price;

    if (!minimum) {
      return "-";
    }

    const formattedPrice = minimum.toFixed(
      Math.max(minimum.toString().length - 2, 2)
    );

    return formattedPrice;
  };

  return (
    <Box
      sx={{
        width: "100%",
        maxHeight: (!isPortalGallery && "100vh") || undefined,
        overflow:
          !isPortalGallery && (scratchOpen || selectedDesign)
            ? "hidden"
            : undefined,
      }}
    >
      <Grid
        container
        spacing={3}
        sx={{
          position: "relative",
          p: 4,
        }}
      >
        <Grid item sx={{ position: "sticky", top: 0, width: "260px" }}>
          <Box>
            <Box sx={{ mb: 1.5 }}>
              Show{" "}
              <Select
                variant="standard"
                value={state.mode}
                onChange={(e) => handleViewModeChange(e.target.value as "all")}
                sx={{ ".MuiInput-input": { pl: 1 } }}
                autoWidth
              >
                <MenuItem value="all" sx={{ pl: 1 }}>
                  {" "}
                  all designs
                </MenuItem>
                <MenuItem value="owned" sx={{ pl: 1 }}>
                  {" "}
                  my designs
                </MenuItem>
                <MenuItem value="template" sx={{ pl: 1 }}>
                  {" "}
                  template designs
                </MenuItem>
              </Select>
            </Box>
            <Typography variant="h5" gutterBottom>
              Products
            </Typography>
            {!tags.loading && (
              <Box>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={state.selectedSizes.includes("46")}
                        value="46"
                        onChange={handleSizeCheck}
                      />
                    }
                    label={'4.25" x 6" Postcards'}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={state.selectedSizes.includes("68")}
                        value="68"
                        onChange={handleSizeCheck}
                      />
                    }
                    label={'6" x 8.5" Postcards'}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={state.selectedSizes.includes("611")}
                        value="611"
                        onChange={handleSizeCheck}
                      />
                    }
                    label={'6" x 11" Postcards'}
                  />
                  {integratedContext.source &&
                    integratedContext.source != "sf" && (
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={state.selectedSizes.includes("811")}
                            value="811"
                            onChange={handleSizeCheck}
                          />
                        }
                        label={'8.5" x 11" Letters'}
                      />
                    )}
                </FormGroup>
              </Box>
            )}
            {tags.loading && (
              <Skeleton variant="rectangular" width="100%" height={200} />
            )}
          </Box>
          <Divider sx={{ my: 3 }} />
          <Box>
            <Typography variant="h5" gutterBottom>
              Industries
            </Typography>
            {!tags.loading && (
              <Box>
                <FormGroup>
                  {tags.industries.map((x) => (
                    <FormControlLabel
                      key={x.tagID}
                      control={
                        <Checkbox
                          checked={state.selectedIndustries.includes(
                            x.tagID.toString()
                          )}
                          value={x.tagID.toString()}
                          onChange={handleIndustryCheck}
                        />
                      }
                      label={x.tagName}
                    />
                  ))}
                  {}
                </FormGroup>
              </Box>
            )}
            {tags.loading && (
              <Skeleton variant="rectangular" width="100%" height={200} />
            )}
          </Box>
          <Divider sx={{ my: 3 }} />
          <Box>
            <Typography variant="h5" gutterBottom>
              Occasions
            </Typography>
            {!tags.loading && (
              <Box>
                <FormGroup>
                  {tags.occasions.map((x) => (
                    <FormControlLabel
                      key={x.tagID}
                      control={
                        <Checkbox
                          checked={state.selectedOccasions.includes(
                            x.tagID.toString()
                          )}
                          value={x.tagID.toString()}
                          onChange={handleOccasionCheck}
                        />
                      }
                      label={x.tagName}
                    />
                  ))}
                  {}
                </FormGroup>
              </Box>
            )}
            {tags.loading && (
              <Skeleton variant="rectangular" width="100%" height={200} />
            )}
          </Box>
        </Grid>
        <Grid item xs={12} md={9} lg xl>
          <Box sx={{ display: "flex", justifyContent: "flex-end", mb: 3 }}>
            <Button
              variant="outlined"
              color="secondary"
              sx={{ mr: 2 }}
              onClick={() =>
                isPortalGallery
                  ? history.push(`/designs/create`)
                  : history.push(
                      `/integrated/design-form?token=${
                        getCookie("token") || undefined
                      }&origin=${origin}&source=${integratedContext.source}`
                    )
              }
            >
              <Typography
                component="span"
                sx={(theme) => ({
                  fontWeight: "bold",
                  color: theme.palette.text.primary,
                })}
              >
                New Design
              </Typography>
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => {
                setState({ ...state, isOnlyFavorites: !state.isOnlyFavorites });
              }}
              sx={{ mr: 2 }}
            >
              {state.isOnlyFavorites ? (
                <FavoriteOutlined sx={{ mr: 1 }} />
              ) : (
                <FavoriteBorder sx={{ mr: 1 }} />
              )}{" "}
              <Typography
                component="span"
                sx={(theme) => ({
                  fontWeight: "bold",
                  color: theme.palette.text.primary,
                })}
              >
                Favorites
              </Typography>
            </Button>
            <TextField
              placeholder="Search"
              onChange={handleSearch}
              value={searchTerm}
              size="small"
            />
          </Box>
          <Grid container spacing={3}>
            {designs.loading && !designs.initialized && (
              <React.Fragment>{getDesignLoadingBoxes()}</React.Fragment>
            )}
            {designs.initialized && (
              <React.Fragment>
                {state.isOnlyFavorites && designs.designs.length === 0 && (
                  <Box>
                    <Typography
                      variant="h4"
                      gutterBottom
                      sx={{ fontSize: "20px" }}
                    >
                      You currently have no favorites.{" "}
                      <a
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          setState({ ...state, isOnlyFavorites: false });
                        }}
                      >
                        Click here
                      </a>{" "}
                      to show all designs.
                    </Typography>
                  </Box>
                )}
                {designs.designs.map((design) => (
                  <Grid item xs={6} md={6} lg={3} xl={3} key={design.designID}>
                    <Box
                      onClick={() => selectDesign(design.designID)}
                      sx={{ cursor: "pointer" }}
                    >
                      <Box
                        sx={{
                          paddingTop: "calc(4.25 / 6 * 100%)",
                          height: "100%",
                          width: "100%",
                          position: "relative",
                          //border: "solid 1px rgba(0,0,0,.125)",
                          backgroundColor: "#F8F8F8",
                          display: "block",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            top: 0,
                            left: 0,
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            height: "100%",
                            position: "absolute",
                            width: "100%",
                            textAlign: "center",
                            // backgroundImage: design.proofFront
                            //   ? `url('${design.proofFront}')`
                            //   : "",
                            // backgroundSize: "cover",
                            // backgroundRepeat: "no-repeat",
                            // backgroundPosition: "center center",
                            // backgroundColor: "rgba(0,0,0,.25)",
                          }}
                        >
                          <Box sx={{ mt: "auto", mb: "auto", width: "auto" }}>
                            <Box sx={{ py: 3, px: 4 }}>
                              <Img src={design.proofFront} />
                            </Box>
                            <Box
                              sx={{
                                position: "absolute",
                                top: "10px",
                                right: "10px",
                              }}
                            >
                              {favorites.favorites.find(
                                (f) => design.designID === f.designID
                              ) ? (
                                <IconButton
                                  sx={{
                                    backgroundColor: "white",
                                    border: "solid 1px rgba(0,0,0,.125)",
                                  }}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    removeFromFavorites(design.designID);
                                  }}
                                >
                                  <Favorite
                                    sx={{ width: "18px", height: "18px" }}
                                  />
                                </IconButton>
                              ) : (
                                <IconButton
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    addToFavorites(design.designID);
                                  }}
                                  sx={{
                                    backgroundColor: "white",
                                    border: "solid 1px rgba(0,0,0,.125)",
                                  }}
                                >
                                  <FavoriteBorder
                                    sx={{ width: "18px", height: "18px" }}
                                  />
                                </IconButton>
                              )}
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                      <Box sx={{ pt: 1 }}>
                        <Box>
                          <strong>{design.size.label}</strong>
                        </Box>
                        <Box>
                          Starting at ${getMinimumPrice(design)} Per Piece
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                ))}
              </React.Fragment>
            )}
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ display: "flex", width: "100%" }}>
              {designs.initialized && (
                <Pagination
                  count={designs.pagination.totalPages}
                  page={designs.pagination.page}
                  onChange={handleChangePage}
                  shape="rounded"
                  sx={{ mx: "auto", mt: 3 }}
                />
              )}
            </Box>
          </Grid>
        </Grid>
      </Grid>
      {scratchOpen && getScratchModal()}
      {selectedDesign && getSelectedDesignModal()}
    </Box>
  );
};

export default DesignGallery;
